import { CLOUD_FRONT_URL } from 'config/constants'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'common',
        'notifications',
        'profile',
        'search',
        'settings',
      ])),
      // Will be passed to the page component as props
    },
  }
}

export default function Custom404() {
  return (
    <div className="flex w-full flex-col sm:h-[calc(100vh-100px)]">
      <video
        autoPlay
        loop
        muted
        playsInline
        className="h-full w-full sm:object-cover"
        id="bg-video"
        src={`${CLOUD_FRONT_URL}/videos/404.mp4`}
      />
    </div>
  )
}
